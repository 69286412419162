export class Video {
    _id?: string
    title: string
    author: string
    position?: string
    company?: string
    image: string
    link: string
    categories: string[]
    published?: boolean
    __v?: number
    deleted?: boolean
    publishDate?: Date
    hashtags: string[]
    updatedAt?: Date
    createdAt?: Date
    constructor (
      _id: string,
      title: string,
      author: string,
      position: string,
      company: string,
      image: string,
      link: string,
      categories: string[],
      published: boolean,
      __v: number,
      deleted: boolean,
      publishDate: Date,
      hashtags: string[],
      updatedAt: Date,
      createdAt: Date
    ) {
      this._id = _id
      this.title = title
      this.author = author
      this.position = position
      this.company = company
      this.image = image
      this.link = link
      this.categories = categories
      this.published = published
      this.__v = __v
      this.deleted = deleted = false
      this.publishDate = publishDate
      this.hashtags = hashtags
      this.updatedAt = updatedAt
      this.createdAt = createdAt
    }
}

export type IVideo = Video

export interface VideoCategory {
    name: string
    key: string
}
