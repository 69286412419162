
import { defineComponent, onBeforeMount, onMounted, ref } from 'vue'
import ModuleHeader from '@/components/commons/boxes/ModuleHeader.vue'
import { useRoute } from 'vue-router'
import { Video } from '@/models/coderplus/classes/Video'
import { CoderplusAPI } from '@/api/coderplus.api'
import LoadingScreen from '@/components/commons/LoadingScreen.vue'
import UpdateVideoForm from '../components/UpdateVideoForm.vue'
export default defineComponent({
  components: {
    ModuleHeader,
    LoadingScreen,
    UpdateVideoForm
  },
  props: {},
  setup () {
    const { params: { videoId } } = useRoute()
    const videoInfo = ref<Video>()
    const loading = ref<boolean>(false)
    onBeforeMount(() => {
      loading.value = true
    })
    onMounted(async () => {
      try {
        loading.value = true
        videoInfo.value = await CoderplusAPI.findVideoById(videoId as string)
        console.log(videoInfo)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    })
    return {
      videoId,
      loading,
      videoInfo
    }
  }
})
