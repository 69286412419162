
import { computed, defineComponent, onMounted, ref } from 'vue'
import FileUpload from 'primevue/fileupload'
import Divider from 'primevue/divider'
import { CoderplusAPI } from '@/api/coderplus.api'
import { Video, VideoCategory } from '@/models/coderplus/classes/Video'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
export default defineComponent({
  components: {
    Divider,
    FileUpload
  },
  props: {
    videoInfoProps: {
      required: true,
      type: Video
    }
  },
  setup (props) {
    const toast = useToast()
    const myVideo = ref<Video>(props.videoInfoProps)
    const imageName = ref<string>('')
    const title = ref<string>(myVideo.value.title)
    const author = ref<string>(myVideo.value.author)
    const position = ref<string>(myVideo.value.position ?? '')
    const company = ref<string>(myVideo.value.company ?? '')
    const hashtags = ref<string[]>(myVideo.value.hashtags)
    const link = ref<string>(myVideo.value.link)
    const base64 = ref<string>('')
    const hashtagText = ref<string>('')
    const showCategory = ref<boolean>(false)
    const newCategory = ref<string>('')
    const categories = ref<VideoCategory[]>(
      [
        {
          name: 'Producto',
          key: 'product'
        },
        {
          name: 'Diseño',
          key: 'diseño'
        },
        {
          name: 'Desarrollo',
          key: 'desarrollo'
        },
        {
          name: 'Marketing',
          key: 'marketing'
        }
      ]
    )
    const selectedCategories = ref<VideoCategory[]>([])
    const disablePublish = computed(() => {
      if (
        title.value === '' ||
        author.value === '' ||
        hashtags.value.length === 0 ||
        base64.value === '' ||
        link.value === '' ||
        selectedCategories.value.length === 0
      ) {
        return true
      }
      return false
    })
    onMounted(async () => {
      if (!categories.value.find(element => element.key === myVideo.value.categories[0]) && myVideo.value.categories.length > 0) {
        categories.value.push({
          name: myVideo.value.categories[0],
          key: myVideo.value.categories[0]
        })
      }
      const aux = categories.value.find(cat => cat.key === myVideo.value.categories[0])
      selectedCategories.value = aux ? [aux] : []
    })
    return {
      disablePublish,
      myVideo,
      imageName,
      link,
      title,
      author,
      position,
      company,
      hashtags,
      categories,
      hashtagText,
      showCategory,
      selectedCategories,
      newCategory,
      changeShow: () => {
        showCategory.value = !showCategory.value
      },
      addCategoryLocal: () => {
        if (!categories.value.find(element => element.key === newCategory.value)) {
          categories.value.push({
            name: newCategory.value,
            key: newCategory.value
          })
        }
      },
      addTag: (event: any) => {
        event.preventDefault()
        const val = event.target.value.trim()
        if (val === '') {
          toast.add({
            summary: ToastSummaries.Error,
            detail: 'No puede agregar un tag vacío',
            severity: ToastSeverities.Error,
            life: ToastLife.Default
          })
        } else if (val.charAt(0) !== '#') {
          toast.add({
            summary: ToastSummaries.Error,
            detail: 'Debe comenzar el tag por #',
            severity: ToastSeverities.Error,
            life: ToastLife.Default
          })
        } else {
          hashtags.value.push(val)
          event.target.value = ''
        }
      },
      removeTag: (index: number) => {
        hashtags.value.splice(index, 1)
      },
      onUpload: (event: any) => {
        imageName.value = event.files[0].name
        const reader = new FileReader()
        reader.readAsDataURL(event.files[0])
        reader.onload = () => {
          if (reader.result !== null) {
            base64.value = reader.result.toString()
          }
        }
      },
      updateVideo: async (change: boolean) => {
        const videoInfo : Video = {
          ...myVideo.value,
          title: title.value,
          author: author.value,
          image: base64.value,
          link: link.value,
          categories: selectedCategories.value.length === 0 ? [] : [selectedCategories.value[0].key],
          hashtags: hashtags.value,
          position: position.value,
          company: company.value,
          ...(change && { published: true })
        }
        try {
          await CoderplusAPI.updateVideoById(myVideo.value._id!, videoInfo)
          toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Video guardado exitosamente',
            life: 3000
          })
        } catch (error) {
          toast.add({
            summary: ToastSummaries.Error,
            detail: 'Ocurrió un error al cargar el video',
            severity: ToastSeverities.Error,
            life: ToastLife.Default
          })
        }
      }
    }
  }
})
