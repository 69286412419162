import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5d9516e3")
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "p-mr-2" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_ModuleHeader = _resolveComponent("ModuleHeader")!
  const _component_UpdateVideoForm = _resolveComponent("UpdateVideoForm")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_LoadingScreen, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ModuleHeader, null, {
          title: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.videoInfo.title), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_UpdateVideoForm, { videoInfoProps: _ctx.videoInfo }, null, 8, ["videoInfoProps"])
      ]))
}